import { FC } from 'react';
import { AspectRatio, Skeleton } from '@chakra-ui/react';

interface PlaceholderImageProps {
  width: number;
  height: number;
}

export const PlaceholderImage: FC<PlaceholderImageProps> = ({
  height,
  width,
}) => {
  return (
    <AspectRatio ratio={width / height}>
      <Skeleton width="100%" height="100%" />
    </AspectRatio>
  );
};
