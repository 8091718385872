import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import {
  ImageFragment,
  ImageStyleFragment,
  MediaImage,
} from '../../generated/types';
import { Image } from '../Common/Image';
import Link from 'next/link';
import { PlaceholderImage } from '../Common/PlaceholderImage';

interface TeaserImageTitleProps {
  title: string;
  url: string;
  image: ImageFragment & {
    imageStyle: ImageStyleFragment;
  };
}

export const TeaserImageTitle: FC<TeaserImageTitleProps> = ({
  url,
  title,
  image,
}) => {
  return (
    <Link href={url} passHref>
      <Box
        display="block"
        as="a"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="sm"
        color="primary"
        bg="white"
        _hover={{
          bg: 'primary',
          color: '#fff',
        }}
      >
        {image ? (
          <Image
            borderTopRadius="sm"
            imageStyle={image.imageStyle}
            image={image}
            hideCopyright
          />
        ) : (
          <PlaceholderImage width={375} height={265} />
        )}
        <Box fontWeight="bold" textAlign="center" p={3}>
          {title}
        </Box>
      </Box>
    </Link>
  );
};
